import { FetchRequest } from "@marketpartner/mp-common-react"
import { LoadingError } from "./LoadingError"
import { LoadingSpinner } from "./LoadingSpinner"

type LoadingProps<Data> = {
    request: FetchRequest<Data>
    render: (data: Data) => JSX.Element
    reloadMode?: string
}

export const Loading = <Data,>({ 
    request, 
    render, 
    reloadMode = 'display-spinner'
}: LoadingProps<Data>) => {
    if (request.isLoading && (reloadMode === 'display-spinner' || !request.data)) {
        return <LoadingSpinner />
    }
    if (request.data) {
        return render(request.data)
    }
    return <LoadingError error={request.error} />
}