import { useFetch } from "@marketpartner/mp-common-react"
import { useBackend } from "../hooks/useBackend"
import { DateTime } from "luxon";
import { EpSession, FlatEpCompany, FlatEpContact, FlatEpNestedContact, FlatEpNestedSession, FlatEpResource, FlatEpSession } from "@marketpartner/backend-api";
import { eventDetails } from "../eventDetails";
import { fromUtcIso } from "../utilityFunctions";

type WithClickable<T> = T & {
    isClickable: boolean
}

export type Date = {
    datestring: string
    datetime: DateTime
}

type WithLuxonTimes<TSession extends FlatEpNestedSession | FlatEpSession | EpSession> = Omit<TSession, "start" | "end"> & {
    start: DateTime
    end: DateTime
}

type FlatEpSessionWithSpeakers = Omit<FlatEpSession, "speakers"> & {
    speakers: Contact[]
}

export type FlatEpNestedContactWithSessions = FlatEpNestedContact & {
    sessions: FlatEpNestedSession[]
}

export type FlatEpSessionWithLuxonTimes = WithClickable<WithLuxonTimes<FlatEpSessionWithSpeakers>>

export type AgendaWithDates = {
    sessions: FlatEpSessionWithLuxonTimes[]
    dates: Date[]
}

export type ContentCategory = {
    id: string
    name: string
}

export type Contact = WithClickable<FlatEpContact>

type SpeakersData = {
    speakers: Contact[]
    categories: ContentCategory[]
}

type ResourceMedia = 'file' | 'vimeo' | 'link'
export type ResourceWithMedia = Omit<FlatEpResource, "file"> & {
    mediaType: ResourceMedia
}

type ResourcesData = {
    resources: ResourceWithMedia[]
    categories: ContentCategory[]
}

export type Company = WithClickable<FlatEpCompany>
type SponsorsData = {
    sponsors: Company[]
    categories: ContentCategory[]
}

export type EventContent = {
    agenda: AgendaWithDates
    speakersData: SpeakersData
    resourcesData: ResourcesData
    sponsorsData: SponsorsData
}

export const useEventContentFetch = () => {

    const { clientId, eventId } = eventDetails
    const backend = useBackend()

    return useFetch(
        async (): Promise<EventContent> => {

            const fetch = await Promise.all([
                backend.content().getEventPartnerSessions(clientId, eventId),
                backend.content().getEventPartnerSpeakers(clientId, eventId),
                backend.content().getEventPartnerResources(clientId, eventId),
                backend.content().getEventPartnerSponsors(clientId, eventId)
            ])

            const [ sessions, speakers, resources, sponsors ] = fetch

            return {
                agenda: {
                    sessions: sessions
                        .map(session => addLuxonTimes(session, session.start, session.end))
                        .map(session => ({
                            ...session,
                            speakers: session.speakers
                                .map(speaker => {
                                    const contact = speakers.find(it => it.entity_token === speaker.entity_token)
                                    if(!contact) {
                                        throw new Error("Speaker not found")
                                    }
                                    return {
                                        ...contact,
                                        category: speaker.category,
                                        categoryTitle: speaker.categoryTitle,
                                    }
                                })
                                .map(speaker => ({
                                    ...speaker,
                                    isClickable: Boolean(speaker.bio) || Boolean(speaker.sessions.length)
                                })),
                        }))
                        .map(session => ({
                            ...session,
                            isClickable: Boolean(session.synopsis) || Boolean(session.speakers.length)
                        })),
                    dates: getDates(sessions)
                },
                speakersData: {
                    speakers: speakers
                        .map(speaker => ({
                            ...speaker,
                            sessions: speaker.sessions
                                .map(session => addLuxonTimes(session, session.start, session.end))
                                .map(session => sessions.find(it => it.entity_token === session.entity_token) as FlatEpSession)
                        }))
                        .map(speaker => ({
                            ...speaker,
                            isClickable: Boolean(speaker.bio) || Boolean(speaker.sessions.length)
                        })),
                    categories: getCategories(speakers)
                },
                resourcesData: {
                    resources: resources.map(transformResource),
                    categories: getCategories(resources)
                },
                sponsorsData: {
                    sponsors: sponsors
                        .map(sponsor => ({
                            ...sponsor,
                            isClickable: Boolean(sponsor.website) && Boolean(sponsor.profile)
                        })),
                    categories: getCategories(sponsors)
                }
            }
        },
        []
    )
}

export function getCategories(entities: FlatEpContact[] | FlatEpResource[] | FlatEpCompany[]): ContentCategory[] {
    return entities
        .map(entity => ({
            id: entity.category,
            name: entity.categoryTitle
        }))
        .filter((cat, index, array) => array.findIndex(it => it.id === cat.id) === index)
    
}

function addLuxonTimes<T extends EpSession | FlatEpSession | FlatEpNestedSession>(session: T, start: string, end: string): WithLuxonTimes<T> {
    return {
        ...session,
        start: fromUtcIso(`${start.replace(' ','T')}Z`),
        end: fromUtcIso(`${end.replace(' ','T')}Z`)
    }
}

function transformResource(resource: FlatEpResource): ResourceWithMedia {
    const { file, url, ...resourceFields} = resource
    const fileOrUrl = file ? file : url
    const mediaType = getMediaType(fileOrUrl)
    return {
        ...resourceFields,
        url: fileOrUrl,
        mediaType
    }
}

function getMediaType(url: string): ResourceMedia {
    if (url.includes('vimeo')) return 'vimeo'
    if (url.includes('.pdf')) return 'file'
    return 'link'
}

export function getDates(sessions: Array<FlatEpSession | FlatEpSessionWithLuxonTimes>) {
    return sessions
        .filter((session, index, arr) => arr.findIndex(it => it.day === session.day) === index)
        .map(session => ({
            datestring: session.day,
            datetime: fromUtcIso(session.day)
        }))
}