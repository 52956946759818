import { createContext, FC, ReactNode, useContext, useState } from "react";
import { Company, Contact, FlatEpSessionWithLuxonTimes, ResourceWithMedia } from "../event-content-context/useEventContentFetch";

type PopupData = {
    type: "session"
    data: FlatEpSessionWithLuxonTimes
} | {
    type: "speaker"
    data: Contact
} | {
    type: "sponsor"
    data: Company
}| {
    type: "resource"
    data: ResourceWithMedia
}

type PopupContextValue = {
    popupData: PopupData | undefined
    showPopup: (data: PopupData) => void
    close: () => void
}

const context = createContext<PopupContextValue>(undefined!)
export const usePopupContext = () => useContext(context)

export const PopupContext: FC<{children: ReactNode}> = ({ children }) => {
    const [popupData, setPopupData] = useState<PopupData | undefined>()
    return <context.Provider value={{
        popupData,
        showPopup: (popupData) => {
            setPopupData(popupData)
        },
        close: () => {
            setPopupData(undefined)
        }
    }}>
        { children }
    </context.Provider>
}