/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TextConfig } from "@marketpartner/backend-api"
import { NonFieldComponent } from "@marketpartner/mp-common-react"
import { FC } from "react"
import parse from 'html-react-parser'

type TextProps = {
    text: string
}
const containerStyle = css`
    font-size: 16px;
    margin-bottom: 5px;
`
export const Text: FC<TextProps> = ({ text }) => {
    return <div css={containerStyle}>{ parse(text) }</div>
}

export const DynamicText: NonFieldComponent<TextConfig> = ({
    config
}) => {
    return <Text 
        text={config.text}
    />
}