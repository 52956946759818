/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PageSection } from "../common/PageSection"
import { LoadedSponsors } from "./LoadedSponsors"
import { FC, useEffect } from "react"
import { useEventContentContext } from "../common/event-content-context/EventContentContext"
import { Loading } from "../common/loading/Loading"
import { LinkButton } from "../common/LinkButton"
import { Link } from "../common/Link"
import { P } from "../common/typography"

export const sponsorsDomId = "sponsors"

export const Sponsors: FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <PageSection sectionHeading="Sponsors" id={sponsorsDomId}>
        <Loading request={eventContentRequest} render={({sponsorsData}) =>
            <LoadedSponsors
                sponsors={sponsorsData.sponsors}
                categories={sponsorsData.categories}
                categoriesToShow={[]}
            />
        } />
        <P css={css`margin-top: 50px`}>If you would like to hear more on sponsorship of The Times CEO Summit please contact <Link href="mailto:ivan.lazarov@news.co.uk">ivan.lazarov@news.co.uk</Link></P>
        {/* <div css={css`text-align: center; margin-top: 30px;`}><LinkButton css={css`margin-top: 30px`} to="../register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}
    </PageSection>

}