/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"

type SubmitButtonProps = {
    onClick: () => void
    children: ReactNode
}

export const SubmitButton: FC<SubmitButtonProps> = ({onClick, children}) => {

    const buttonStyle = css`
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 1px;
        border: none;
        border-radius: 3px;
        padding: 10px 20px 8px;
        color: #fff;
        background: #134682;
        text-decoration: none;
        box-shadow: 1px 1px 3px #5f5f5f;
        cursor: pointer;
        width: 100%;
        margin-top: 10px;
        -webkit-appearance: none;
    `

    return <div css={buttonStyle} onClick={onClick}>{children}</ div>

}