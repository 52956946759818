/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PageSection } from "../common/PageSection"
import { LoadedSpeakers } from "./LoadedSpeakers"
import { Loading } from "../common/loading/Loading"
import { FC, useEffect } from "react"
import { useEventContentContext } from "../common/event-content-context/EventContentContext"
import { LinkButton } from "../common/LinkButton"

export const speakersDomId = "speakers"

export const Speakers: FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <PageSection id={speakersDomId} sectionHeading="Speakers">
        <Loading request={eventContentRequest} render={({speakersData}) =>
                <LoadedSpeakers
                    speakers={speakersData.speakers}
                    categories={speakersData.categories}
                    categoriesToShow={["speaker"]} />
        } />
        {/* <div css={css`text-align: center; margin-top: 30px;`}><LinkButton css={css`margin-top: 30px`} to="../register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}
    </PageSection>

}