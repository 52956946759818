/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { Contact } from "../common/event-content-context/useEventContentFetch"
import { usePopupContext } from "../common/popup/PopupContext"
import { getFullName } from "../speakers/Speaker"
import { SessionSpeakerAvatar } from "./SessionSpeakerAvatar"
import { SessionSpeakerDetails } from "./SessionSpeakerDetails"

type SessionSpeakerProps = {
    speaker: Contact
}

export const SessionSpeaker: FC<SessionSpeakerProps> = ({ speaker }) => {

    const sessionSpeakerStyle = css`
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
    `

    const popupContext = usePopupContext()
    const showSpeakerPopup = () => popupContext.showPopup({data: speaker, type: 'speaker'})

    return <div css={sessionSpeakerStyle} onClick={showSpeakerPopup}>
        <SessionSpeakerAvatar src={speaker.profile_photo_cropped} alt={getFullName(speaker)}/>
        <SessionSpeakerDetails speaker={speaker} />
    </div>

}
