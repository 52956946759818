/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Session } from "./Session"
import { FC, useState } from "react"
import { Date, FlatEpSessionWithLuxonTimes } from '../common/event-content-context/useEventContentFetch'
import { P } from "../common/typography"
import { eventDetails } from "../common/eventDetails"

const agendaBoxStyle = css`
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const datesMenuStyle = css`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
    text-align: center;
`
const dateStyle = css`
    border-bottom: 3px solid #e6e6e6;
    width: 130px;
    padding-bottom: 10px;
    cursor: pointer;
    @media (max-width: 600px) {
        font-size: 16px;
        width: 100px;
        padding-bottom: 5px;
    }
`
const timeZoneStyle = css`
    text-align: left;
    font-size: 14px;
    margin: 0;
    @media (max-width: 600px) {
        font-size: 12px;
    }
`
type LoadedAgendaProps = {
    sessions: FlatEpSessionWithLuxonTimes[]
    dates: Date[]
}

export const LoadedAgenda: FC<LoadedAgendaProps> = ({ sessions, dates }) => {

    const [selectedDay, setSelectedDay] = useState<string | undefined>(dates[0]?.datestring ?? undefined)

    return <div css={agendaBoxStyle}>

        {
            dates.length > 1
                && <div css={datesMenuStyle}>
                    {dates.map((date, index) =>
                        <div
                            key={index}
                            css={css`${dateStyle} ${ date.datestring === selectedDay && 'border-color: #134682' } `}
                            onClick={ () => setSelectedDay(date.datestring) }
                        >{date.datetime.toFormat('MMMM d')}</div>
                    )}
                </div>
        }

        <P css={timeZoneStyle}>All times are displayed in {eventDetails.timeZone}</P>

        {sessions
            .filter(session => session.day === selectedDay)
            .map((session, index) =>
                <Session key={index} session={session} /> 
        )}

    </div>

}