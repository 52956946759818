import { useState, useEffect, useContext, createContext, FC, ReactNode } from 'react'

type ViewportContextValue = {
    width: number
    height: number
    isDesktop: boolean
    isMobile: boolean
}

const mobileThreshold = 600
const viewportContext = createContext<ViewportContextValue>(undefined!);
export const useViewport = () => useContext(viewportContext)

export const ViewportContext: FC<{children: ReactNode}> = ({ children }) => {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const handleWindowResize = () => {
        if (!document.fullscreenElement) {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <viewportContext.Provider value={{
            width,
            height,
            isDesktop: width > mobileThreshold,
            isMobile: width <= mobileThreshold,
        }}>
            {children}
        </viewportContext.Provider>
};