export const carouselImages = [
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_01.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_02.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_03.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_04.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_05.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_06.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_07.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_08.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_09.jpg",
        caption: ''
    },
    {
        url: "https://times-ceosummit.com/resources/carousel-2024/CEO_summit2024_10.jpg",
        caption: ''
    },
];