/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"

type SponsorLogoProps = {
    src: string
    alt: string
}

export const SponsorLogo: FC<SponsorLogoProps> = ({src, alt}) => {

    const logoStyle = css`
        width: 160px;
        margin: 0 auto;
    `

    return <img src={src} alt={alt} css={logoStyle} />

} 