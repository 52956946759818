/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { CountrySelectConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { FieldError } from "../FieldError"

export type Choice = {
    value: string
    label: string
    disabled?: boolean
}

type CountrySelectProps = {
    label: string
    required: boolean
    choices: Choice[]
    value: string
    setValue: (value: string) => void
    errors: string[]
    className?: string
}

export const CountrySelect: FC<CountrySelectProps> = ({
    label,
    required,
    choices,
    value,
    setValue,
    errors,
    ...props
}) => {

    const containerStyle = css`
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        text-align: left;
        width: 300px;
        flex: 1 1 auto;
        font-size: 16px;
        margin-bottom: 10px;
    `

    const labelStyle = css`
        margin-bottom: 5px;
    `

    const inputStyle = css`
        height: 40px;
        border: 1px solid #000;
        padding: 10px;
        width: 100%;
        font-size: 15px;
        outline: none;
        background-image:
            linear-gradient(45deg, transparent 50%, #000 50%),
            linear-gradient(135deg, #000 50%, transparent 50%),
            linear-gradient(to right, #000, #000);
        background-position:
            calc(100% - 18px) calc(1em + 3px),
            calc(100% - 13px) calc(1em + 3px),
            calc(100% - 2.5em) 8px;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
        -moz-appearance: none; 
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    `

    return <label css={containerStyle} {...props}>
        {label && <div css={labelStyle}>{label}</div>}
        <FieldError errors={errors} />
        <select css={inputStyle}
            required={required}
            value={value}
            onChange={(e) => setValue(e.target.value)}
        >
            <option value="" disabled={true}>Select country</option>
            {choices.sort(sortByLabel).map((choice) => (
                <option key={choice.value}
                    value={choice.value}
                    disabled={choice.disabled}
                >
                    {choice.label}
                </option>
            ))}
        </select>
    </label>
}

export const DynamicCountrySelect: FieldComponent<CountrySelectConfig> = ({
    config,
    value,
    setValue,
    errors
}) => <CountrySelect
        label={config.label}
        value={value ?? config.defaultValue}
        setValue={setValue}
        required={config.required}
        choices={config.choices}
        errors={errors}
    />

function sortByLabel(choiceA: Choice, choiceB: Choice): 1 | 0 | -1 {
    if (choiceA.value === 'United Kingdom') {
        return -1
    }
    if (choiceB.value === 'United Kingdom') {
        return 1
    }
    if (choiceA.label > choiceB.label) {
        return 1
    }
    if (choiceA.label < choiceB.label) {
        return -1
    }
    return 0

}