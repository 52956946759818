/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PageSection } from '../common/PageSection'
import { P } from '../common/typography'
import { Link } from "../common/Link";
import venue from "../images/news-uk.jpg"
import { ExternalRegButton } from "../registration/ExternalRegButton";
import { FC } from "react";

export const venueDomId = "venue"

export const Venue: FC = () => {

    const venueStyle = css`
        display: block;
        margin: 0 auto 30px;
        width: 100%;
        max-width: 800px;
        box-shadow: 2px 2px 5px;
    `

    return <PageSection sectionHeading="Event Details" id={venueDomId}>

        <img src={venue} alt="venue" css={venueStyle} />

        <P>Event location: <b>Baker Hughes Conference Suite, P&J Live, East Burn Road, Stoneywood, Aberdeen, AB21 9FX</b></P>

        <P>P&J Live is located close to Aberdeen International Airport, six miles northwest of the city centre, and just a few short minutes from the city's new western peripheral route. You can reach the venue using a range of transport options.</P>

        <P>Please <Link href="https://www.pandjlive.com/visiting/getting-here/" target="_blank">visit the P&J Live website</Link> for further information on how to get to the venue.</P>

        <P>If you require accommodation, Hilton Aberdeen TECA is adjacent to the venue:<br /><Link href="https://urldefense.com/v3/__https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=ABZECHI&groupCode=90Q&arrivaldate=2023-11-27&departuredate=2023-11-28&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT__;!!F0Stn7g!FOqtybDd8VJzdsITdaKgPj55YvXhVzTzK2FS26Xvh2xQDeeU0009u2v4_t401yHNvo8UQPM0aKl8Ggvq8iRegNMBFZoMWp6YFg50olA%24">Book your accommodation at Hilton Aberdeen TECA</Link></P>

        <div css={css`text-align: center`}><ExternalRegButton css={css`margin-top: 50px`}>REGISTER NOW</ ExternalRegButton></div>

    </PageSection>

}
