/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"
import { scrollTo } from "./utilityFunctions"

type LinkProps = {
    children: ReactNode
    sectionId?: string
    href?: string
    target?: string
    className?: string
}

export const Link: FC<LinkProps> = ({children, sectionId, href, target, ...props}) => {

    const linkStyle = css`
        color: #134682;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    `

    return <>
        {
            href
                ? <a href={href} css={linkStyle} target="_blank" rel="noreferrer" {...props}>{children}</a>
                : <div onClick={() => scrollTo(sectionId)} css={linkStyle} {...props}>{children}</div>
        }
    </>

}
