/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { H3 } from "../common/typography"
import { SessionSpeaker } from "./SessionSpeaker"
import { usePopupContext } from "../common/popup/PopupContext"
import { FC } from "react"
import { FlatEpSessionWithLuxonTimes } from "../common/event-content-context/useEventContentFetch"
import parse from "html-react-parser"
import { Button } from "../common/Button"

type SessionProps = {
    session: FlatEpSessionWithLuxonTimes
}

export const Session: FC<SessionProps> = ({session}) => {

    const sessionStyle = css`
        display: flex;
        background-color: ${session.entity_category === 'Heading' ? '#e6e6e6' : '#fff'};
        border: 1px solid #e6e6e6;
        border-radius: 3px;
        margin-top: ${session.tags.includes('breakout') ? '-12px' : ''};
        @media (max-width: 450px) {
            flex-direction: column;
        }
    `
    const timeStyle = css`
        display: flex;
        flex-shrink: 0;
        align-content: flex-start;
        color: #fff;
        background-color: #134682;
        border-radius: 3px 0 0 3px;
        width: 80px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        flex-wrap: wrap;
        @media (max-width: 450px) {
            width: 100%;
            padding: 6px 10px;
        }
    `
    const timeSpanStyle = css`
        padding: 0 4px;
        @media (min-width: 450px) {
            width: 100%;
        }
    `
    const hiddenTimeStyle = css`
        width: 80px;
        display: flex;
        flex-shrink: 0;
        cursor: pointer;
        ${ session.tags.includes('breakout') && css`
            &::after {
                border-radius: 50%;
                width: 20px;
                height: 20px;
                content: '';
                background-color: #e6e6e6;
                margin: auto;
            }` 
        }
        @media (max-width: 450px) {
            display: none;
        }
    `
    const detailsStyle = css`
        display: flex;
        flex-direction: column;
        padding: 15px;
    `
    const sessionTitleStyle = css`
        text-align: left;
        cursor: pointer;
    `

    const popupContext = usePopupContext()
    const showSessionPopup = () => {
        popupContext.showPopup({data: session, type: 'session'})
    }
    const startEndSame = session.start.toMillis() === session.end.toMillis()

    return <div css={sessionStyle}>
        {
            session.tags.includes('breakout') || session.entity_category === 'Heading'
            ? <div css={hiddenTimeStyle} onClick={showSessionPopup}></div> 
            : <div css={timeStyle} onClick={showSessionPopup}>
                {!startEndSame && <span css={timeSpanStyle}>{session.start.toFormat('h:mm')} - </span>}<span css={timeSpanStyle}>{session.end.toFormat('h:mm a')}</span>
            </div>
        }
        <div css={detailsStyle}>
            <H3 css={sessionTitleStyle} onClick={showSessionPopup}>{session.name}</H3>

            {
                session.resources.some(resource => resource.category === 'video') && <Button css={css`margin: 10px 0; padding: 5px 10px; text-align: center; max-width: 250px;`} onClick={showSessionPopup}>Watch on demand</Button>
            }

            <div css={css`text-align: left; font-size: 16px;`}>{parse(session.synopsis)}</div>

            {
                session.speakers
                    .map(speaker => <SessionSpeaker 
                        key={`${session.entity_token}-${speaker.entity_token}`} 
                        speaker={speaker} 
                    />)
            }
        </div>
    </div>

}