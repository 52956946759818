import { RegistrationForm } from "@marketpartner/backend-api"
import { useFetch } from "@marketpartner/mp-common-react"
import { FC } from "react"
import { useBackend } from "../common/hooks/useBackend"
import { Loading } from "../common/loading/Loading"
import { LoadedRegistration } from "./LoadedRegistration"
import { eventDetails } from "../common/eventDetails"

export const categoryId = 'delegate'

export const registrationDomId = 'registration'

export const Registration:FC = () => {

    const backend = useBackend()
    const { clientId, eventId } = eventDetails

    const registrationFormFetch = useFetch(
        async (): Promise<RegistrationForm> => {
            return await backend.forms().getForCategory(clientId, eventId, categoryId)
        }, []
    )
    return <div id={registrationDomId}>
        <Loading
            request={registrationFormFetch}
            render={form => <LoadedRegistration
                form={form}
            />}
        />
    </div>
}