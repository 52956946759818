/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { agendaDomId } from "../agenda/Agenda"
import { LinkElement } from "../common/LinkElement"
import { PageSection } from '../common/PageSection'
import { H4, P } from '../common/typography'
import { ReactComponent as Arrow } from '../images/arrow.svg'
import { LinkButton } from "../common/LinkButton"
import { Carousel } from "../carousel/Carousel"

export const eventInfoDomId = "about"

export const EventInfo: FC  = () => {

    const infoStyle = css`
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 0 auto 30px;
        max-width: 700px;
    `
    const infoBoxStyle = css`
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 170px;
        border-top: 5px solid #134682;
        border-radius: 3px;
        padding: 15px 10px;
        background-color: #ededed;
        color: #000;
    `
    const infoBoxTitle = css`
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #000;
    `
    const infoBoxDetails = css`
        margin: 0 auto 10px;
    `
    const infoBoxButtonStyle = css`
        display: flex;
        gap: 5px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 2px;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    `
    const arrowStyle = css`
        fill: #134682;
        width: 18px;
        height: 18px;
        @media (max-width: 600px) {
            width: 14px;
        }
    `


    return <PageSection id={eventInfoDomId} css={css`background-color: #fafafa;`}>

        {/* <H2 css={css`text-align: center;`}>Event Date: Tuesday, February 6, 2024<br />Event Time: 8:45am - 2pm (GMT)<br />Event Location: The News Building<br />1 London Bridge Street, London, SE1 9GF</H2> */}

        <div css={infoStyle}>
            <div css={infoBoxStyle}>
                <H4 css={infoBoxTitle}>When</H4>
                <P css={infoBoxDetails}>
                    Thursday, June 20, 2024<br />
                    8am-2pm (including lunch)
                </P>
                <LinkElement to='/agenda' scrollTo={agendaDomId}>
                    <div css={infoBoxButtonStyle}>
                        AGENDA
                        <Arrow css={arrowStyle} />
                    </div>
                </ LinkElement>
            </div>
            <div css={infoBoxStyle}>
                <H4 css={infoBoxTitle}>Where</H4>
                <P css={infoBoxDetails}>
                    The News Building<br />
                    1 London Bridge Street<br />
                    London, SE1 9GF
                </P>
                <LinkElement external={true} to="https://www.google.com/maps/place/News+UK/@51.5051669,-0.0899672,17z/data=!3m2!4b1!5s0x4875fcc5bcce8e77:0xb43a74ad4ff59792!4m6!3m5!1s0x4876033641af07db:0xff0573bcae7974f!8m2!3d51.5051669!4d-0.0873923!16s%2Fg%2F1vq9lrzd?entry=ttu">
                    <div css={infoBoxButtonStyle}>
                        LOCATION
                        <Arrow css={arrowStyle} />
                    </div>
                </LinkElement>
            </div>
        </div>

        <P css={css`text-align: left;`}>At this year’s CEO summit our theme was Searching for growth: how UK companies can get ahead in an era of AI, economic stagnation and political upheaval.</P>

        <P css={css`text-align: left;`}>Our two panel sessions debated the key issues for UK plc:</P>
        <P css={css`text-align: left;`}>
            <ul>
                <li>Selling Britain: How do we sell Britain to overseas investors? And how does Britain sell to the rest of the world? What changes can help to make life easier for exporters and the UK a more attractive destination for capital?</li>
                <li>AI: Chief executives explain how to successfully integrate AI to improve productivity while managing the impact on staff.</li>
            </ul>
        </P>

        <P css={css`text-align: left;`}>Our full agenda and speaker line-up is <LinkElement to='/agenda' scrollTo={agendaDomId}>here</LinkElement>.</P>

        <Carousel />

        {/* <div css={css`text-align: center`}><LinkButton css={css`margin-top: 30px`} to="register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}

    </PageSection>

}
