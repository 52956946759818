/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { Link } from "../common/Link"
import { PageSection } from '../common/PageSection'
import { P } from '../common/typography'
import alix from '../images/alix-logo.png'
import service from '../images/service-logo.png'
import { LinkButton } from "../common/LinkButton"

export const partnerDomId = "partner"

export const Partner: FC = () => {

    const logoStyle = css`
        display: block;
        width: 100%;
        max-width: 240px;
        margin: 0 auto;
        text-align: center;
    `

    return <PageSection id={partnerDomId} sectionHeading="Sponsors">

        <Link href="https://www.alixpartners.com/" target="_blank"><img css={logoStyle} src={alix} alt="partner" /></Link>

        <P>Alix Partners is a results-driven global consulting firm that specializes in helping businesses respond quickly and decisively to their most critical challenges - from urgent performance improvement to complex restructuring, from risk mitigation to accelerated transformation. These are the moments when everything is on the line - a sudden shift in the market, an unexpected performance decline, a time-sensitive deal, a fork-in-the-road decision. We stand shoulder to shoulder with our clients until the job is done, and only measure our success in terms of the results we deliver. We partner with you to make the right decisions and take the right actions. And we are right by your side. When it really matters.</P>

        <P css={css`margin-bottom: 40px;`}><Link href="https://www.alixpartners.com/" target="_blank">alixpartners.com</Link></P>

        <Link href="https://www.servicenow.com/uk/" target="_blank"><img css={logoStyle} src={service} alt="partner" /></Link>

        <P>ServiceNow brings organisations in every industry, smarter, faster and better ways to work. Our cloud‑based platform helps digitise and unify organisations, putting AI to work across your business so that people can work better. Making old tech work with new tech. Reaching across departments, from the front office to the back office and every office in between. Supercharging developers. Empowering customer service. Reinventing how your people work.</P>
        <P>On our intelligent platform, AI isn’t just a promise – it’s happening today. Simply. Seamlessly. Freeing your people to do the work that matters.</P>
        <P>Because when your people work better, everything works better.</P>

        <P css={css`margin-bottom: 40px;`}><Link href="https://www.servicenow.com/uk/" target="_blank">servicenow.com/uk</Link></P>

        {/* <div css={css`text-align: center; margin-top: 30px;`}><LinkButton css={css`margin-top: 30px`} to="../register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}

    </PageSection>

}
