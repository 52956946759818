import { agendaDomId } from "../agenda/Agenda"
import { contactDomId } from "../event-info/Contact"
import { galleryDomId } from "../event-info/Gallery"
import { partnerDomId } from "../event-info/Partner"
import { supplementDomId } from "../event-info/Supplement"
import { venueDomId } from "../event-info/Venue"
import { externalLink } from "../registration/ExternalRegButton"
import { registrationDomId } from "../registration/Registration"
import { squadUpDomId } from "../registration/SquadUp"
import { advisoryBoardDomId } from "../speakers/AdvisoryBoard"
import { speakersDomId } from "../speakers/Speakers"
import { sponsorsDomId } from "../sponsors/Sponsors"

export type NavLink = {
    text: string
    to: string
    scrollTo?: string
    type: 'internal' | 'external'
}

const navLinkLabels = ['home', 'agenda', 'speakers', 'advisoryBoard', 'sponsors', 'registration', 'externalRegistration', 'squadUp', 'partner', 'contact', 'venue', 'gallery', 'supplement'] as const

type NavLinksMap = Record<typeof navLinkLabels[number], NavLink>

export const navLinks: NavLinksMap = {
    home: {
        text: 'Home',
        to: '/',
        type: 'internal'
    },
    agenda: {
        text: 'Agenda',
        to: '/agenda',
        scrollTo: agendaDomId,
        type: 'internal'
    },
    speakers: {
        text: 'Speakers',
        to: '/speakers',
        scrollTo: speakersDomId,
        type: 'internal'
    },
    advisoryBoard: {
        text: 'Advisory Board',
        to: '/advisory-board',
        scrollTo: advisoryBoardDomId,
        type: 'internal'
    },
    sponsors: {
        text: 'Sponsors',
        to: '/sponsors',
        scrollTo: sponsorsDomId,
        type: 'internal'
    },
    registration: {
        text: 'Register',
        to: '/register',
        scrollTo: registrationDomId,
        type: 'internal'
    },
    externalRegistration: {
        text: 'Register',
        to: externalLink,
        type: 'external'
    },
    squadUp: {
        text: 'Register',
        to: '/squad-up',
        scrollTo: squadUpDomId,
        type: 'internal'
    },
    partner: {
        text: 'Sponsors',
        to: '/partners',
        scrollTo: partnerDomId,
        type: 'internal'
    },
    contact: {
        text: 'Contact',
        to: '/contact',
        scrollTo: contactDomId,
        type: 'internal'
    },
    venue: {
        text: 'Venue',
        to: '/venue',
        scrollTo: venueDomId,
        type: 'internal'
    },
    gallery: {
        text: 'Gallery',
        to: '/gallery',
        scrollTo: galleryDomId,
        type: 'internal'
    },
    supplement: {
        text: '2024 Supplement',
        to: '/supplement',
        scrollTo: supplementDomId,
        type: 'internal'
    },
}
