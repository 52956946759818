/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PageSection } from "../common/PageSection"
import { FC, useEffect, useState } from "react"
import { P } from "../common/typography"
import { Link } from "../common/Link"

export const squadUpDomId = 'squad-up-reg'

export const SquadUpReg: FC = () => {

    const [showSquadup, setShowSquadup] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowSquadup(true)
        }, 500)
    }, [])

    useEffect(() => {
        if (showSquadup) {

            // Create a script element
            const script = document.createElement('script');
            script.textContent = `
                squadup = {
                    title: 'Select Tickets Below',
                    image: 'https://newsuklive.app/resources/sunday-times-logo-black.svg',
                    root: 'squadup-checkout',
                    userId: [5132364],
                    eventId: 78535,
                    confirmEmail: true,
                    brandingPosition: 'none',
                    ticketGuardianEnabled: false,
                    descriptionEnabled: true,
                    stripePublicKey: 'pk_live_oD1onCP9xXJ9GF2InW8QHN8G',
                    textTicketEnabled: false,
                    stripeJsVersion: 'v3',
                    shoppingCartEnabled: false
                }
            `;

            // Create another script element for loading the external JavaScript file
            const externalScript = document.createElement('script');
            externalScript.src = 'https://s3.amazonaws.com/checkout.squadup.com/main-v2.min.js';

            // Append the external script to the document's body
            document.head.appendChild(externalScript);

            // Append the script to the document's head
            document.head.appendChild(script);

            // Clean up the added scripts when the component is unmounted
            return () => {
                document.head.removeChild(script);
                document.head.removeChild(externalScript);
            };
        }
    }, [showSquadup]);

    return <PageSection id={squadUpDomId} sectionHeading="Book Tickets">

        <P css={css`max-width: 600px; margin: 0 auto 40px;`}>
            By purchasing a ticket for this event you agree to the <Link href="https://times-event.com/resources/the_times_crossword_championship_rules_2023.pdf" target="_blank">terms and conditions</Link> of the competition.
            <br /><br />
            Times subscribers are eligible for a 20% discount, please email <Link href="mailto:puzzles@thetimes.co.uk">puzzles@thetimes.co.uk</Link> with the subject line 'TCC code' to receive the discount code.
        </P>

        <div id="squadup-checkout"></div>
        
    </PageSection>

}