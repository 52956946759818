/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { NavLink } from "react-router-dom"
import { FC, ReactNode } from "react"
import { useScrollContext } from './ScrollContext'

type LinkElementProps = {
    to: string
    children: ReactNode
    scrollTo?: string
    className?: string
    external?: boolean
    onClick?: () => void
}

const linkStyle = css`
    text-decoration: none;
`

export const LinkElement: FC<LinkElementProps> = ({  
    to,
    scrollTo, 
    external,
    onClick,
    ...props
}) => {

    const { onSetScrollTo } = useScrollContext()

    function handleClick() {
        if (onClick) {
            onClick()
        }
        onSetScrollTo(scrollTo)
    }

    return <>
        { external 
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            ? <a href={to} target="_blank" rel="noreferrer" css={linkStyle} {...props} /> 
            : <NavLinkWrapper
                to={to}
                onClick={handleClick}
                css={linkStyle}
                {...props}
            /> }
    </>
}

type NavLinkWrapperProps = {
    to: string
    onClick?: () => void
    children?: ReactNode
    className?: string
}

const NavLinkWrapper: FC<NavLinkWrapperProps> = ({
    to,
    className,
    ...props
}) => {
    return <NavLink 
        to={to}
        style={{ textDecoration: 'none' }}
        {...props}
    />
}