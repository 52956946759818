/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { P } from '../common/typography'
import { Link } from "../common/Link";
import { ExternalRegButton } from "../registration/ExternalRegButton";
import { FC } from "react";
import { PageSection } from "../common/PageSection";

export const contactDomId = "faqs"

export const Contact: FC = () => {

    return <PageSection sectionHeading="Contact Us" id={contactDomId}>

        <P>For general event queries contact The Times Events Team at <Link href="mailto:foodanddrinksummit@thetimes.co.uk">foodanddrinksummit@thetimes.co.uk</Link></P>
        <P>For speaking opportunities contact Lucy Phillips, Head of Event Programming <Link href="mailto:lucy.phillips@thetimes.co.uk">lucy.phillips@thetimes.co.uk</Link></P>
        <P>For sponsorship opportunities and information on having an exhibition stand contact Caroline McLinden, Sales Manager, <Link href="mailto:caroline.mclinden@news-scotland.co.uk">caroline.mclinden@news-scotland.co.uk</Link></P>

        <div css={css`text-align: center`}><ExternalRegButton css={css`margin-top: 50px`}>REGISTER NOW</ ExternalRegButton></div>

    </PageSection>

}
