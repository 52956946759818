/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PageSection } from "../common/PageSection"
import { LoadedSpeakers } from "./LoadedSpeakers"
import { Loading } from "../common/loading/Loading"
import { FC, useEffect } from "react"
import { useEventContentContext } from "../common/event-content-context/EventContentContext"
import { P } from "../common/typography"
import { LinkButton } from "../common/LinkButton"

export const advisoryBoardDomId = "advisory-board"

export const AdvisoryBoard: FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <PageSection id={advisoryBoardDomId} sectionHeading="Advisory Board">
        <P css={css`text-align: left; margin-bottom: 50px;`}>Our CEO Summit advisory board helps The Times ensure our programme is as relevant as possible and that we address the real concerns and interests of industry leaders.</P>
        <Loading request={eventContentRequest} render={({speakersData}) =>
                <LoadedSpeakers
                    speakers={speakersData.speakers}
                    categories={speakersData.categories}
                    categoriesToShow={["advisory_board"]} />
        } />
        {/* <div css={css`text-align: center; margin-top: 30px;`}><LinkButton css={css`margin-top: 30px`} to="../register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}
    </PageSection>

}