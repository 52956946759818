/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import { NavItem } from './NavItem'
import { navLinks } from "./navLinks"

export const Nav: FC = () => {

    const navStyle = css`
        display: flex;
        flex-wrap: wrap;
        background-color: #000;
        justify-content: center;
        gap: 10px 40px;
        padding: 12px;
        position: sticky;
        top: 0;
        z-index: 2;
        @media (max-width: 600px) {
            padding: 10px
        }
    `

    const navItems = [navLinks.home, navLinks.agenda, navLinks.speakers, navLinks.advisoryBoard, navLinks.supplement, navLinks.partner]

    return <div css={navStyle}>
        {
            navItems.map((navLink) => <NavItem 
                key={navLink.to}
                navLink={navLink}
            />)
        }
    </div>
    

}