import { FC, ReactNode } from "react";
import { Button } from "../common/Button";
import { useUrlParametersContext } from "../common/UrlParameterContext";

type ExternalRegButtonProps = {
    children: ReactNode
    className?: string
}

export const externalLink = ""

export const ExternalRegButton: FC<ExternalRegButtonProps> = ({children, ...props}) => {

    const { urlParameters} = useUrlParametersContext()
    const utmSource = urlParameters.find(param => param[0] === "utm_source")
    const utmSourceValue = utmSource ? `?source=${utmSource[1]}` : ""

    return <Button href={`${externalLink}${utmSourceValue}`} {...props}>{children}</Button>

}