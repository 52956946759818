/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { PageSection } from '../common/PageSection'
import { galleryImages } from "./galleryImages"

export const galleryDomId = "gallery"

export const Gallery: FC = () => {

    const containerStyle = css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    `

    const imageStyle = css`
        max-width: 440px;
    `

    return <PageSection id={galleryDomId} sectionHeading="Gallery">
        <div css={containerStyle}>
            {galleryImages.map(galleryImage => (
                <img css={imageStyle} src={galleryImage.src} alt={galleryImage.alt} key={galleryImage.alt} />
            ))}
        </div>
    </PageSection>
}
