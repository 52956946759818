/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { H3 } from "../common/typography"
import { SessionSpeaker } from "./SessionSpeaker"
import parse from "html-react-parser"
import { FlatEpSessionWithLuxonTimes } from "../common/event-content-context/useEventContentFetch"
import { SessionVideo } from "./SessionVideo"

type SessionPopupContentProps = {
    session: FlatEpSessionWithLuxonTimes
}

export const SessionPopupContent: FC<SessionPopupContentProps> = ({ session }) => {

    const startEndSame = session.start.toMillis() === session.end.toMillis()

    return <>
        <H3 css={css`margin-bottom: 10px`}>{session.name}</H3>
        {
            session.resources
                .filter(resource => resource.category === 'video')
                .map(resource => <SessionVideo key={resource.entity_token} resource={resource} sessionName={session.name} />)
        }    
        {
            session.tags.includes("breakout") || session.entity_category === "Heading"
                ? ''
                : <H3 css={css`font-weight: 400;`}>{session.start.toFormat("dd MMMM")}<br />{session.start.toFormat('h:mm a')}{!startEndSame && <> - {session.end.toFormat('h:mm a')}</>}</H3>
        }
        <div>{parse(session.synopsis)}</div>
        {
            session.speakers
                .map(speaker => <SessionSpeaker 
                    key={`${session.entity_token}-${speaker.entity_token}`}
                    speaker={speaker} 
                />)
        }
    </>
}