/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import avatar from "../images/avatar.png"

type SessionSpeakerAvatarProps = {
    src: string
    alt: string
}

export const SessionSpeakerAvatar: FC<SessionSpeakerAvatarProps> = ({src, alt}) => {

    const avatarStyle = css`
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    width: 50px;
    box-shadow: 2px 3px 5px -1px #a5a5a5;
`

return <img src={src ? src : avatar} alt={alt} css={avatarStyle} />

}