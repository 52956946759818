import { PublicEventDetails } from "@marketpartner/backend-api";
import { createContext, FC, ReactNode, useContext } from "react";
import { useBackend } from "../common/hooks/useBackend";
import { Loading } from "../common/loading/Loading";
import { useFetch } from "@marketpartner/mp-common-react";
import { eventDetails } from "./eventDetails";

type EventContextValue = PublicEventDetails

const context = createContext<EventContextValue>(undefined!)
export const useEventContext = () => useContext(context)

export const EventContext: FC<{children: ReactNode}> = ({ children }) => {

    const { clientId, eventId } = eventDetails

    const backend = useBackend()
    const eventFetch = useFetch(
        () => backend.events().getEvent(clientId, eventId),
        []
    )

    return <Loading 
        request={eventFetch}
        render={event => <context.Provider value={event}>
            { children }
        </context.Provider>}
    />
     
}