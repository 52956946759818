import { UrlParameterConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { FC, useEffect } from "react"
import { useUrlParametersContext } from "../../UrlParameterContext"
// import { useConfigContext } from "../../config/ConfigContext"
type UrlParameterProps = {
    setValue: (value: string) => void
    name: string
    defaultValue: string
}
export const UrlParameter: FC<UrlParameterProps> = ({ setValue, name, defaultValue }) => {

    const { urlParameters } = useUrlParametersContext()

    useEffect(() => {
        const urlParam = urlParameters.find(param => param[0] === name)
        const value = urlParam && urlParam[1]
        setValue(value ?? defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <></>

}

export const DynamicUrlParameter: FieldComponent<UrlParameterConfig> = ({
    config, 
    setValue
}) => {

    return <UrlParameter
        name={config.name}
        setValue={setValue}
        defaultValue={config.defaultValue}
    />

}