/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Speaker } from "./Speaker"
import { FC } from "react"
import { Contact, ContentCategory } from "../common/event-content-context/useEventContentFetch"
import { P } from "../common/typography"

type LoadedSpeakersProps = {
    speakers: Contact[]
    categories: ContentCategory[]
    categoriesToShow: string[]
}

export const LoadedSpeakers: FC<LoadedSpeakersProps> = ({ speakers, categories, categoriesToShow = [] }) => {

    const speakersBoxStyle = css`
        display: flex;
        flex-wrap: wrap;
        gap: 30px 6px;
        justify-content: center;
    `

    const filteredSpeakers = speakers
        .filter(speaker => !categoriesToShow.length || categoriesToShow.includes(speaker.category))

    return <div css={speakersBoxStyle}>

        { speakers.length === 0 && <P>Speakers to be announced</P>}

        {filteredSpeakers.map((speaker, index) =>
            <Speaker key={index} speaker={speaker} />
        )}
        
    </div>

}