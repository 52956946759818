/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { H3, H5 } from "../common/typography"
import { getFullName, getJobTitleAndCompany } from "./Speaker"
import { SpeakerAvatar } from "./SpeakerAvatar"
import { SpeakerPopupAgendaSubContent } from "./SpeakerPopupAgendaSubContent"
import parse from 'html-react-parser'
import { Contact } from "../common/event-content-context/useEventContentFetch"

type SpeakerPopupProps = {
    speaker: Contact
}

const bio = css`
    padding: 10px 0;
    font-size: 16px;
    text-align: left;
`
export const SpeakerPopupContent: FC<SpeakerPopupProps> = ({ speaker }) => {
    
    return <>
        <SpeakerAvatar src={speaker.profile_photo_cropped} alt={getFullName(speaker)} />
        <H3 css={css`font-family: Gotham`}>{getFullName(speaker)}</H3>
        <H5>{getJobTitleAndCompany(speaker)}</H5>
        <div css={bio}>{parse(speaker.bio)}</div>
        <SpeakerPopupAgendaSubContent speaker={speaker} />
    </>
}