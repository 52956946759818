/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { SpeakerAvatar } from "./SpeakerAvatar"
import { H3, H5 } from "../common/typography"
import { usePopupContext } from "../common/popup/PopupContext"
import { FC } from "react"
import { Contact } from "../common/event-content-context/useEventContentFetch"

type SpeakerProps = {
    speaker: Contact
}

export const Speaker: FC<SpeakerProps> = ({speaker}) => {

    const speakerStyle = css`
        width: 220px;
        cursor: pointer;
        text-align: center;
    `

    const popupContext = usePopupContext()
    const showSpeakerPopup = () => popupContext.showPopup({data: speaker, type: 'speaker'})

    return <div css={speakerStyle} onClick={showSpeakerPopup}>
            <SpeakerAvatar src={speaker.profile_photo_cropped} alt={getFullName(speaker)} />
            <H3 css={css`font-family: Gotham`}>{getFullName(speaker)}</H3>
            <H5>{getJobTitleAndCompany(speaker)}</H5>
    </div>

}

export function getFullName(speaker: Contact): string {
    return `${speaker.prefix && `${speaker.prefix} `}${speaker.first_name}${(speaker.first_name && speaker.last_name) && ` `}${speaker.last_name}${speaker.suffix && ` ${speaker.suffix}`}`
}

export function getJobTitleAndCompany(speaker: Contact): string {
    return `${speaker.job_title}${(speaker.job_title && speaker.company) ? ', ' : ''}${speaker.company}`
}