/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react"
import { FC } from "react"

export const LoadingSpinner:FC<{absolute?: boolean, className?: string}> = ({ absolute, ...props }) => {

    const loadingStyle =  css`
        height: 100%;
        display: flex;
        background: #ffffffaa;
        margin: auto;
        padding: 50px;
        ${absolute && `position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 999;`}
    `

    const iconStyle = css`
        margin: auto;
        width: 60px;
        height: 60px;
        stroke: #134682;
        enable-background: new 0 0 100 100;
    `

    const rotation = keyframes`
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    `

    const circleStyle = css`
        fill: none;
        stroke-width: 6;
        stroke-miterlimit: 10;
        stroke-dasharray: 80,210;
        transform-origin: center;
    `

    const animation1 = css`
        animation: ${rotation} 4s infinite ease-in-out;
    `

    const animation2 = css`
        animation: ${rotation} 1.6s infinite ease-in-out;
    `

    const animation3 = css`
        animation: ${rotation} .8s infinite ease-in-out;
    `

    return <>
        <div css={loadingStyle}>
            <svg
                 version="1.1"
                id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px"
                viewBox="0 0 100 100"
                css={[iconStyle, animation1]}
                xmlSpace="preserve">
                <circle css={[circleStyle, animation2]} cx="50" cy="50" r="46.1" />
                <circle css={[circleStyle, animation3]} cx="50" cy="50" r="46.1" />
            </svg>
        </div>
    </>
}