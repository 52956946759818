/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"

type TypographyProps = {
    children: ReactNode
    className?: string
    onClick?: () => void
}

export const H1: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 42px;
        font-weight: 700;
        margin: 0 auto 40px;
        width: 100%;
        max-width: 700px;
        @media (max-width: 600px) {
            font-size: 25px;
        }
    `

    return <h1 
        css={style}
        {...props}
    >{children}</h1>

}

export const H2: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 25px;
        font-weight: 700;
        margin: 0 auto 40px;
        width: 100%;
        @media (max-width: 600px) {
            font-size: 20px;
            margin: 0 auto 20px;
        }
    `

    return <h2 
        css={style}
        {...props}
    >{children}</h2>

}

export const H3: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 18px;
        font-weight: 700;
        color: #134682;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    `

    return <h3
        css={style}
        {...props}
    >{children}</h3>

}

export const H4: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 16px;
        font-weight: 500;
        color: #134682;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    `

    return <h4
        css={style}
        {...props}
    >{children}</h4>

}

export const H5: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin: 0 auto;
        width: 100%;
    `

    return <p
        css={style}
        {...props}
    >{children}</p>

}

export const P: FC<TypographyProps> = ({children, ...props}) => {

    const style = css`
        font-size: 18px;
        text-align: center;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    `

    return <p
        css={style}
        {...props}
    >{children}</p>

}
