/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from 'react'
import banner from './banner-2.png'

export const Banner: FC = () => {

    const bannerContainerStyle = css`
        display: flex;
        width: 100%;
        flex: 1 1 auto;
    `

    const bannerStyle = css`
        background: url(${banner});
        background-position: center 62%;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 auto;
        width: 90%;
        height: 90%;
        max-width: 500px;
        z-index: 1;
        @media (max-width: 1100px) {
            max-width: 360px;
        }
        @media (max-width: 700px) {
            max-width: 240px;
        }
        @media (max-width: 500px) {
            max-width: 210px;
        }
    `

    return <>
        <div css={bannerContainerStyle}>
            <div css={bannerStyle} />
        </div>
    </>

}
