/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import './App.css';
import { AppContainer } from './AppContainer'
import { Hero } from './hero/Hero'
import { PopupContext } from "./common/popup/PopupContext";
import { Popup } from "./common/popup/Popup";
import { ViewportContext } from "./common/ViewportContext";
import { FC } from "react";
import { EventContext } from "./common/EventContext";
import { UrlParametersContext } from "./common/UrlParameterContext";
import { EventContentContext } from "./common/event-content-context/EventContentContext";
import { Footer } from "./footer/Footer";
import { Nav } from "./nav/Nav";
import { HashRouter, Route, Routes } from "react-router-dom";
import { pages } from "./common/pages";
import { ScrollContext } from "./common/ScrollContext";

const appStyle = css`
    height: 100%;
`

export const App: FC = () => {

    return (
        <HashRouter>
            <ViewportContext>
                <UrlParametersContext>
                    <div className='app' id='app' css={appStyle}>
                        <EventContentContext>
                            <PopupContext>
                                <ScrollContext>
                                    <EventContext>
                                        <AppContainer>
                                            <Popup />
                                            <Nav />
                                            <Hero />
                                            <Routes>
                                                {
                                                    pages.map(page => <Route
                                                        key={page.path}
                                                        element={page.element}
                                                        path={page.path}
                                                    />)
                                                }
                                            </Routes>
                                            <Footer />
                                        </AppContainer>
                                    </EventContext>
                                </ScrollContext>
                            </PopupContext>
                        </EventContentContext>
                    </div>
                </UrlParametersContext>
            </ViewportContext>
        </HashRouter>
    );

}