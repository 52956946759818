/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CountryCode, PhoneNumberConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { FC } from "react"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FieldError } from '../FieldError'

type StyledPhoneInputProps = {
    value: string
    setValue: (value: string) => void
    className?: string
    preferredCountries: CountryCode[]
    defaultCountryCode: CountryCode | undefined
    placeholder: string
}

type PhoneNumberProps = Omit<StyledPhoneInputProps, 'className'> & {
    label: string
    errors: string[]
}

const containerStyle = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    flex: 1 1 auto;
    font-size: 16px;
    margin-bottom: 10px;
`

const labelStyle = css`
    padding: 0 0 5px;
`

const inputStyle = css`
    height: 40px;
    color: #000000;
    display: flex;
    width: 100%;
    border: 1px solid #000;
    background: white;
    & .PhoneInputCountry {
        border: none !important;
        padding-right: 5px;
        padding-left: 10px;
        border-right: 1px solid #000 !important;
    }
    & input {
        flex: 1 1 auto;
        width: 100%;
        border-radius: 0 !important;
        border: none !important;
        outline: none !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-right: 10px !important;
        font-size: 15px !important;
        height: 38px !important;
        color: #000;
    }
`

export const PhoneNumber: FC<PhoneNumberProps> = ({
    label,
    errors,
    ...props
}) => {
    return <label css={containerStyle}>
        <div css={labelStyle}>{label}</div>
        <FieldError errors={errors} />
        <StyledPhoneInput
            css={inputStyle}
            {...props}
        />
    </label>

}


const StyledPhoneInput: FC<StyledPhoneInputProps> = ({
    value,
    setValue,
    className,
    preferredCountries,
    defaultCountryCode,
    placeholder
}) => {

    const defaultCountry = defaultCountryCode ?? preferredCountries[0] ?? 'US'

    return <PhoneInput
        value={value ?? ''}
        onChange={setValue}
        className={className}
        defaultCountry={defaultCountry}
        countryOptionsOrder={preferredCountries.length ? [...preferredCountries, '|'] : []}
        placeholder={placeholder}
        addInternationalOption={false}
    
    />
}

export const DynamicPhoneNumber: FieldComponent<PhoneNumberConfig> = ({
    value,
    setValue,
    config,
    errors
}) => {
    return <PhoneNumber
        value={value ?? config.defaultValue}
        setValue={setValue}
        label={config.label}
        errors={errors}
        preferredCountries={config.preferredCountries}
        defaultCountryCode={config.defaultCountryCode}
        placeholder={config.placeholder}
    />
}