/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { PageSection } from "../common/PageSection"
import { DynamicFormElements, useDynamicFormState, useUpdate } from '@marketpartner/mp-common-react'
import { FC, useState } from "react"
import { useBackend } from '../common/hooks/useBackend'
import { LoadingSpinner } from '../common/loading/LoadingSpinner'
import { dynamicElements } from '../common/form/fields/dynamicElements'
import { RegistrationSuccessMessage } from "./RegistrationSuccessMessage"
import { SubmitButton } from './SubmitButton'
import { ErrorWithStatus, FormError } from '../common/form/FormError'
import { FormStatus, formStatus, RegistrationForm } from '@marketpartner/backend-api'
import { eventDetails } from '../common/eventDetails'
import { H2, P } from '../common/typography'
import { Link } from '../common/Link'

type LoadedRegistrationProps = {
    form: RegistrationForm
}

export const LoadedRegistration: FC<LoadedRegistrationProps> = ({form}) => {

    const { clientId, eventId } = eventDetails
    const backend = useBackend()
    const formState = useDynamicFormState(form.elements, "complete")
    const [formError, setFormError] = useState<ErrorWithStatus>()
    const [formSubmitted, setFormSubmitted] = useState(false)

    const registrationRequest = useUpdate({
        update: async () => {
            setFormError(undefined)
            document.getElementById('register')?.scrollIntoView({behavior: "smooth"})
            if (formState.invalidFields.length) {
                const error = new Error() as ErrorWithStatus
                error.type = "InvalidFields"
                throw error
            }
            const response = await backend.registration().registerForEvent(clientId, eventId, {
                fields: formState.values,
                categoryId: form.registrationCategoryId
            })
            if (response.isAlreadyRegistered) {
                const error = new Error() as ErrorWithStatus
                error.code = 409
                error.response = {
                    status: 409
                }
                throw error
            }
            return response
        },
        onError: (error: any) => {
            setFormError(error)
        },
        onSuccess: () => {
            setFormSubmitted(true);
            (window as any).gtag('event', 'registration successful', {
                'event_label': 'Disrupting for Better - Driving change in an era of division',   //form name e.g -tech digital summit
                'event_category': 'registration'
            });    
        }

    })

    const backgroundStyle = css`
        border-bottom: 5px solid #134682;
    `

    const containerStyle = css`
        display: flex;
        flex-wrap: wrap;
        gap: 10px 15px;
        padding: 20px 30px;
        width: 100%;
        background-color: #fff;
        border: solid 1px #dbe2ea;
        border-top: 5px solid #134682;
        ${(formError && formState.invalidFields.length > 0) && css`
            & .field-error {
                display: initial !important;
            }
        `}
    `

    return <PageSection id="register" css={backgroundStyle}>

        <div css={containerStyle}>

            { formError && <FormError error={formError} />}

            {
                formStatus(form) === FormStatus.Closed
                    ? <P css={css`margin: 50px auto;`}>Registration is now closed for the event, if you have any questions please contact <Link href="mailto:timesceosummit@thetimes.co.uk">timesceosummit@thetimes.co.uk</Link> and a member of the events team will get back to you.</P>
                    : <>
                        { formSubmitted 
                            ? <RegistrationSuccessMessage />
                            : <>
                                <H2 css={css`text-align: center; margin-bottom: 20px;`}>Register now</H2>
                                { registrationRequest.isLoading && <LoadingSpinner absolute={true} />}
                                <DynamicFormElements
                                    elementComponents={dynamicElements}
                                    state={formState}
                                    />
                                    <div css={css`font-size: 16px; width: 100%;`}>Your information will be used in accordance with our <Link href="https://newsprivacy.co.uk/">Privacy Policy</Link></div>
                                <SubmitButton onClick={registrationRequest.trigger}>REGISTER</SubmitButton>
                            </>
                        }
                    </>
            }

        </div>

    </PageSection>
}