type imageGallery = {
    src: string
    alt: string
}[]

export const galleryImages: imageGallery = [
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0247.JPG",
        alt: 'image1'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0264.JPG",
        alt: 'image2'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0277.JPG",
        alt: 'image3'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0315.JPG",
        alt: 'image4'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0467.JPG",
        alt: 'image5'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0481.JPG",
        alt: 'image6'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0543.JPG",
        alt: 'image7'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0632.JPG",
        alt: 'image8'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0663.JPG",
        alt: 'image9'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0735.JPG",
        alt: 'image10'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_0764.JPG",
        alt: 'image11'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_1027.JPG",
        alt: 'image12'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_1076.JPG",
        alt: 'image13'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_1167.JPG",
        alt: 'image14'
    },
    {
        src: "https://times-event.com/resources/drivingchange-gallery/SWS_TT_Disrupting_For_Better_1344.JPG",
        alt: 'image15'
    }
]