/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { Company } from "../common/event-content-context/useEventContentFetch"
import { SponsorLogo } from "./SponsorLogo"
import parse from "html-react-parser"

type SponsorPopupProps = {
    sponsor: Company
}

export const SponsorPopupContent: FC<SponsorPopupProps> = ({ sponsor }) => {

    const bio = css`
        padding: 30px 0;
        font-size: 14px;
    `

    return <>
        <SponsorLogo src={sponsor.logo_cropped} alt={sponsor.name} />
        <div css={bio}>{parse(sponsor.profile)}</div>
    </>
}