/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { CheckboxConfig } from "@marketpartner/backend-api";
import { FieldComponent } from "@marketpartner/mp-common-react";
import { FC } from "react";
import { FieldError } from '../FieldError';

export type CheckboxProps = {
    value: boolean
    setValue: (checked: boolean) => void
    label: string
    errors?: string[]
    className?: string
}

export const Checkbox: FC<CheckboxProps> = ({
    value,
    setValue,
    label,
    errors,
    ...props
}) => {

    const labelStyle = css`
        display: flex;
        position: relative;
        overflow: hidden;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        font-size: 16px;
        width: 100%;
        flex: 1 1 auto;
    `

    const checkboxStyle = css`
        position: absolute;
        left: -1000px;
        width: 1px;
        height: 1px;
    `

    const boxStyle = css`
        width: 14px;
        height: 14px;
        border: 1px solid #000000;
        border-radius: 2px;
        flex-shrink: 0;
        margin: 0 10px 0 0;
        position: relative;
        background: white;
    `

    const markStyle = css`
        width: 4px;
        height: 10px;
        border: 2px solid #000000;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
        position: absolute;
        bottom: 1px;
        left: 4px;
        opacity: 0;
        transition: opacity .1s;
        ${value && css`
            opacity: 1;
        `}
    `

    return <label css={labelStyle} {...props}>
        { errors && <FieldError errors={errors} /> }
        <input css={checkboxStyle}
            type="checkbox"
            checked={value}
            onChange={() => setValue(!value)}
        />
        <div css={boxStyle}><div css={markStyle} /></div>
        <div>{label}</div>
    </label>
}

export const DynamicCheckbox: FieldComponent<CheckboxConfig> = ({
    config,
    errors,
    setValue,
    value
}) => <Checkbox
    label={config.label}
    errors={errors}
    setValue={setValue}
    value={value ?? config.defaultValue}
/>