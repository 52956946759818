/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"
import { H2 } from "./typography"

type PageSectionProps = {
    children: ReactNode
    sectionHeading?: string
    id?: string
    className?: string
}

export const PageSection: FC<PageSectionProps> = ({children, sectionHeading, ...props}) => {

    const pageSectionStyle = css`
        // border-top: solid 1px #dbe2ea;
        background-color: #f6f6f6;
    `

    const innerStyle = css`
        padding: 50px 30px 60px;
        max-width: 960px;
        margin: 0 auto;
        @media (max-width: 600px) {
            padding: 40px 30px 50px;
        }
    `

    return <div css={pageSectionStyle} {...props}>
        <div css={innerStyle}>
            { sectionHeading && <H2 css={css`text-align: center`}>{sectionHeading}</H2> }
            {children}
        </div>
    </div>

}