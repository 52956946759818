/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TextareaConfig } from '@marketpartner/backend-api'
import { FieldComponent } from '@marketpartner/mp-common-react'
import { FC } from "react"
import { FieldError } from '../FieldError'

type TextareaProps = {
    value: string
    required: boolean
    placeholder: string
    setValue: (value: string) => void
    label: string
    errors: string[]
}

const containerStyle = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
`
const labelStyle = css`
    padding: 0 0 5px;
`
const inputStyle = css`
    border: 1px solid #000;
    outline: none;
    min-height: 80px;
    width: 100%;
    padding: 10px;
    font-size: 15px;
    color: #000;
    resize: none;
`

export const Textarea: FC<TextareaProps> = ({ 
    value, 
    required, 
    setValue, 
    placeholder, 
    label,
    errors
}) => {
    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setValue(e.target.value)
    }
    return <label css={containerStyle}>
        <div css={labelStyle}>{ label }</div>
        <FieldError errors={errors} />
        <textarea
            css={inputStyle}
            onChange={handleChange}
            required={required}
            value={value}
            placeholder={placeholder}
        />
    </label>
}

export const DynamicTextarea: FieldComponent<TextareaConfig> = ({
    value,
    setValue,
    config,
    errors
}) => <Textarea
    value={value ?? config.defaultValue}
    setValue={setValue}
    required={config.required}
    placeholder={config.placeholder}
    label={config.label}
    errors={errors}
/>