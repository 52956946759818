/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { H3, P } from "../common/typography"
import { eventDetails } from "../common/eventDetails"
import { FC } from "react"

export const RegistrationSuccessMessage: FC = () => {

    const containerStyle = css`
        text-align: center;
        padding: 0 0 50px;
        margin: 0 auto;
    `

    const linkStyle = css`
        color: #134682;
        text-decoration: none;
    `

    const { clientId, eventId } = eventDetails

    return <div css={containerStyle}>
        <H3>Thank you for registering your interest in this event, you will receive a confirmation email once your registration is approved.</H3>
        <P>
            Now save this event to your calendar:<br />
            <a href={`https://mw.marketpartner.com/calendar/?clientId=${clientId}&eventId=${eventId}&type=ics`} css={linkStyle}>Outlook</a> | <a target="_blank" rel="noreferrer" href={`https://mw.marketpartner.com/calendar/?clientId=${clientId}&eventId=${eventId}&type=gcal`} css={linkStyle}>Google</a> | <a href={`https://mw.marketpartner.com/calendar/?clientId=${clientId}&eventId=${eventId}&type=ics`} css={linkStyle}>iCal</a>
        </P>
    </div>
}