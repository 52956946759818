/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { FlatEpSessionWithLuxonTimes } from "../common/event-content-context/useEventContentFetch"
import { usePopupContext } from "../common/popup/PopupContext"
import { H3 } from "../common/typography"

type SpeakerPopupSessionProps = {
    session: FlatEpSessionWithLuxonTimes
}

export const SpeakerPopupSession: FC<SpeakerPopupSessionProps> = ({ session }) => {
    
    const sessionStyle = css`
        display: flex;
        background-color: #fff;
        border: 1px solid #e6e6e6;
        margin: 10px 100px;
        @media (max-width: 600px) {
            margin: 10px 0;
        }
        @media (max-width: 450px) {
            flex-direction: column;
        }
    `

    const timeStyle = css`
        display: flex;
        flex-shrink: 0;
        align-content: flex-start;
        color: #fff;
        background-color: #134682;
        width: 80px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        flex-wrap: wrap;
        @media (max-width: 450px) {
            width: 100%;
            padding: 6px 10px;
        }
    `
    const timeSpanStyle = css`
        padding: 0 4px;
        @media (min-width: 450px) {
            width: 100%;
        }
    `
    const detailsStyle = css`
        display: flex;
        flex-direction: column;
        padding: 15px;
    `

    const sessionTitleStyle = css`
        text-align: left;
        cursor: pointer;
    `

    const popupContext = usePopupContext()
    const showSessionPopup = () => {
        popupContext.showPopup({ data: session, type: 'session' })
    }
    const startEndSame = session.start.toMillis() === session.end.toMillis()

    return <div css={sessionStyle} onClick={showSessionPopup}>
        <div css={timeStyle}>
            {!startEndSame && <span css={timeSpanStyle}>{session.start.toFormat('h:mm')} - </span>}<span css={timeSpanStyle}>{session.end.toFormat('h:mm a')}</span>
        </div>
        <div css={detailsStyle}>
            <H3 css={sessionTitleStyle}>{session.name}</H3>
        </div>
    </div>

}