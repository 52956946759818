/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { Link } from "../Link"
import { H3, P } from "../typography"
import { FC } from "react"
import { eventDetails } from "../eventDetails"

export type ErrorWithStatus = Error & {
    code: number
    response: {
        status: number
    }
    type?: "InvalidFields"
}

type FormErrorProps = {
    error: ErrorWithStatus
}

const containerStyle = css`
    text-align: center;
    width: 100%;
`

export const FormError: FC<FormErrorProps> = ({ error }) => {

    if (error.type === "InvalidFields") {
        return <div css={containerStyle}>
            <H3 css={css`color: #b33; margin-bottom: 20px;`}>Invalid fields, please check your details below.</H3>
        </div> 
    }
    const { clientId, eventId } = eventDetails

    const errorStatus = error.response?.status
    const knownErrors = [400, 401, 403, 409, 500, 502]
    const fatalErrors = [401, 403, 500, 502]

    return <div css={containerStyle}>
        {errorStatus === 409 && <>
            <H3 css={css`color: #b33;`}>You are already registered for this event</H3>
            <P>Please check your inbox for previous correspondence.</P>
            <P><Link href={`mailto:support@marketpartner.com?subject=${clientId} ${eventId} registration error: 409`}>Contact support</Link></P>
        </>}
        {errorStatus === 400 && <>
            <H3 css={css`color: #b33;`}>Something went wrong with your submission.</H3>
            <P>Please check your details and try again.</P>
            <P><Link href={`mailto:support@marketpartner.com?subject=${clientId} ${eventId} registration error: 400`}>Contact support</Link></P>
        </>}
        {fatalErrors.includes(errorStatus) && <>
            <H3 css={css`color: #b33;`}>An error occurred.</H3>
            <P>To resolve the issue <Link href={`mailto:support@marketpartner.com?subject=${clientId} ${eventId} registration error: ${fatalErrors.toString()}`}>contact support</Link>.</P>
        </>}
        {!(knownErrors.includes(errorStatus)) && <>
            <H3 css={css`color: #b33;`}>An error occurred.</H3>
            <P>Please try registering using your personal device using the same company email.<br />It appears that your company firewall is blocking this form submission.</P>
            <P><Link href={`mailto:support@marketpartner.com?subject=${clientId} ${eventId} registration error: ${errorStatus}`}>Contact support</Link></P>
        </>}

    </div>
}