import { useLocalStorage } from "@marketpartner/mp-common-react"
import { eventDetails } from "../eventDetails"

export const useEventLocalStorage = <T>(key: string, value: T) => {

    const { clientId, eventId } = eventDetails

    const eventKey = `${clientId}-${eventId}-${key}`

    return useLocalStorage(eventKey, value)

}
