/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { H3 } from "../common/typography"
import { SpeakerPopupSession } from "./SpeakerPopupSession"
import { useEventContentContext } from "../common/event-content-context/EventContentContext"
import { Contact, FlatEpSessionWithLuxonTimes, getDates } from "../common/event-content-context/useEventContentFetch"

type SpeakerPopupAgendaSubContentProps = {
    speaker: Contact
}

export const SpeakerPopupAgendaSubContent: FC<SpeakerPopupAgendaSubContentProps> = ({ speaker }) => {

    const { findSession } = useEventContentContext()

    const sessions = speaker.sessions.map(session => findSession(session.entity_token) as FlatEpSessionWithLuxonTimes)

    const sessionDates = getDates(sessions)

    return <>
        {
            sessionDates.map(date => 
                <div key={date.datestring}>
                    <H3 css={css`font-weight: 400;`}>{date.datetime.toFormat("EEEE, LLLL dd")}</H3>
                    {
                        sessions
                            .filter(session => session.day === date.datestring)
                            .map(session => <div key={session.entity_token}>
                                <SpeakerPopupSession session={session} />
                            </div>)
                    }
                </div>)
        }
    </>
}