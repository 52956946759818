import { DateTime } from "luxon";

export function scrollTo(id: string | undefined) {
    const el = document.getElementById(id as string)
    if (el) {
        el.scrollIntoView({behavior: 'smooth'})
    }
}

export function slugify (string: string): string {
    if (string) {
        let str = string.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to = "aaaaeeeeiiiioooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
    return ''
}

export function getEnvironment(): 'dev' | 'live' {
    const host = window.location.host
    const pathname = window.location.pathname.replaceAll('/','')
    if (host === "localhost:3000" || pathname === "bnef-dev") {
        return "dev"
    }
    return "live"
}

export const fromUtcIso = (datetimeString: string) => DateTime.fromISO(datetimeString, { zone: "utc" })