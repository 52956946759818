/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { SessionPopupContent } from "../../agenda/SessionPopupContent"
import { SpeakerPopupContent } from "../../speakers/SpeakerPopupContent"
import { SponsorPopupContent } from "../../sponsors/SponsorPopupContent"
import { usePopupContext } from "./PopupContext"

const containerStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 10px;
    z-index: 2;
    @media (min-width: 720px) {
        padding: 50px;
    }
`

const backgroundStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
`

const popupStyle = css`
    background: white;
    width: 100%;
    max-width: 750px;
    padding: 50px;
    margin: auto;
    position: relative;
    text-align: center;
    border-radius: 3px;
    max-height: 100%;
    overflow-Y: auto;
    border-top: 5px solid #134682;
`

const closeIcon = css`
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
`

export const Popup: FC = () => {
    const popupContext = usePopupContext()
    const { popupData, close } = popupContext

    return <>
        {popupData &&
            <div css={containerStyle}>
                <div css={backgroundStyle} onClick={close}></div>
                <div css={popupStyle}>
                    <div css={closeIcon} onClick={close}><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 32 32" css={css`fill: #666666;`}><path d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z"></path></svg></div>

                    { popupData.type === 'speaker' && <SpeakerPopupContent speaker={popupData.data} /> }
                    { popupData.type === 'sponsor' && <SponsorPopupContent sponsor={popupData.data} /> }
                    { popupData.type === 'session' && <SessionPopupContent session={popupData.data} />}

                </div>
            </div>
        }
    </>
}