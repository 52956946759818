import { ReactNode } from "react"
import { Agenda } from "../agenda/Agenda"
import { Contact } from "../event-info/Contact"
import { EventInfo } from "../event-info/EventInfo"
import { Gallery } from "../event-info/Gallery"
import { Partner } from "../event-info/Partner"
import { Supplement } from "../event-info/Supplement"
import { Venue } from "../event-info/Venue"
import { Registration } from "../registration/Registration"
import { SquadUpReg } from "../registration/SquadUp"
import { AdvisoryBoard } from "../speakers/AdvisoryBoard"
import { Speakers } from "../speakers/Speakers"
import { Sponsors } from "../sponsors/Sponsors"

export type Page = {
    title: string
    path: string
    element: ReactNode
}

export const pages: Array<Page> = [
    {
        title: "Home",
        path: "",
        element: <EventInfo />
    },
    {
        title: 'Speakers',
        path: 'speakers',
        element: <Speakers />,
    },
    {
        title: 'AdvisoryBoard',
        path: 'advisory-board',
        element: <AdvisoryBoard />,
    },
    {
        title: 'Agenda',
        path: 'agenda',
        element: <Agenda />,
    },
    {
        title: 'Sponsors',
        path: 'sponsors',
        element: <Sponsors />,
    },
    {
        title: 'Partner',
        path: 'partners',
        element: <Partner />,
    },
    {
        title: 'Contact',
        path: 'contact',
        element: <Contact />,
    },
    {
        title: 'Venue',
        path: 'venue',
        element: <Venue />,
    },
    {
        title: 'Gallery',
        path: 'gallery',
        element: <Gallery />,
    },
    {
        title: 'Register',
        path: 'register',
        element: <Registration />,
    },
    {
        title: 'Register',
        path: 'squad-up',
        element: <SquadUpReg />,
    },
    {
        title: '2024 Supplement',
        path: 'supplement',
        element: <Supplement />,
    }
]