/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { CheckboxesConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { FC  } from "react"
import { FieldError } from "../FieldError"
import { Checkbox } from "./Checkbox"

type CheckboxesProps = {
    label: string
    required: boolean
    value: string[]
    setValue: (value: string[]) => void
    choices: {
        label: string
        value: string
    }[]
    className?: string
    errors: string[]
}

export const Checkboxes: FC<CheckboxesProps> = ({
    label, 
    value, 
    setValue, 
    required, 
    choices,
    errors,
    ...props
}) => {

    const containerStyle = css`
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        font-size: 16px;
        width: 300px;
        flex: 1 1 auto;
    `

    const labelStyle = css`
        margin-bottom: 5px;
    `

    const toggleChecked = (choiceValue: string) => {
        if (value.includes(choiceValue)) {
            setValue(value.filter((item) => item !== choiceValue))
        } else {
            setValue([...value, choiceValue])
        }
    }

    return <div css={containerStyle} {...props}>
        <div css={labelStyle}>{label}</div>
        <FieldError errors={errors} />
        <div>
            { choices.map(choice => <Checkbox
                key={choice.value}
                label={choice.label}
                value={value.includes(choice.value)}
                setValue={() => toggleChecked(choice.value)}
                css={css`margin-bottom: 4px;`}
            />)}
        </div>
    </div>

}

export const DynamicCheckboxes: FieldComponent<CheckboxesConfig> = ({
    config, 
    value, 
    setValue,
    errors
}) => {

    return <Checkboxes
        label={config.label} 
        value={value ?? config.defaultValue} 
        setValue={setValue} 
        required={config.required} 
        choices={config.choices} 
        errors={errors}
    />

}