/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { Company, ContentCategory } from "../common/event-content-context/useEventContentFetch.js"
import { P } from "../common/typography"
import { Sponsor } from "./Sponsor"

type LoadedSponsorsProps = {
    sponsors: Company[]
    categories: ContentCategory[]
    categoriesToShow: string[]
}

export const LoadedSponsors: FC<LoadedSponsorsProps> = ({ sponsors, categories, categoriesToShow = [] }) => {

    const sponsorsBoxStyle = css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 30px 6px;
        justify-content: center;
        // text-align: center;
        align-content: center;
    `

    const filteredSponsors = sponsors
        .filter(sponsor => !categoriesToShow.length || categoriesToShow.includes(sponsor.category))

    return <div css={sponsorsBoxStyle}>
        {/* {filteredSponsors.map((sponsor, index) =>
            <Sponsor key={index} sponsor={sponsor} />
        )} */}
        {categories.map(category => <>
            <P css={css`margin-bottom: 0`}>{category.name}</P>
            {sponsors.filter(sponsor => sponsor.category === category.id).map((sponsor, index) =>
                <Sponsor key={index} sponsor={sponsor} />
            )}
        </>)}
    </div>

}