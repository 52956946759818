/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { LinkElement } from "../common/LinkElement"
import { NavLink } from "./navLinks"
import { ReactComponent as Arrow } from './arrow-down.svg'

type NavItemProps = {
    navLink: NavLink
}

export const NavItem: FC<NavItemProps> = ({ navLink }) => {

    const navItemStyle = css`
        text-decoration: none;
        color: #fff;
        letter-spacing: 2px;
        font-size: 16px;
        font-family: Gotham;
        font-weight: 500;
        cursor: pointer;
        @media (max-width: 600px) {
            font-size: 13px;
            letter-spacing: 1.5px;
        }
        &:hover {
            color: #00aae3;
        }
    `

    const iconStyle = css`
        vertical-align: middle;
        width: 18px;
        height: 18px;
        fill: #fff;
        margin-left: 5px;
        @media (max-width: 600px) {
            width: 14px;
            height: 14px;
        }
    `
    
    return <LinkElement
        to={navLink.to} 
        scrollTo={navLink.scrollTo} 
        external={navLink.type === 'external'} 
    >
        <div css={navItemStyle}>
            {navLink.text}
            <Arrow css={iconStyle} />
        </div>
    </LinkElement>
    
}    
