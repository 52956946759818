/** @jsxImportSource @emotion/react */
import bg from './bg-3.png'
import {Banner} from './Banner'
import { css } from '@emotion/react'
// import bgVideo from './bg-video.mp4'
import { FC } from 'react'

export const Hero: FC = () => {

    const heroStyle = css`
        background: url(${bg});
        background-position: center center;
        background-size: cover;
        background-color: #134682;
        display: flex;
        flex-direction: column;
        height: 400px;
        overflow: hidden;
        position: relative;
        @media (max-width: 1100px) {
            height: 300px;
        }
        @media (max-width: 700px) {
            height: 200px;
        }
        @media (max-width: 500px) {
            height: 170px;
        }
    `

    // const bgVideoStyle = css`
    //     height: 100%;
    //     ${'' /* width: 177.77777778vh; */}
    //     min-width: 100%;
    //     min-height: 56.25vw;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    // `

    return <div css={heroStyle}>
        {/* <video css={bgVideoStyle} autoPlay playsInline muted loop src={bgVideo}></video> */}
        <Banner />
    </div>

}