/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { Contact } from "../common/event-content-context/useEventContentFetch"
import { H4, H5 } from "../common/typography"
import { getJobTitleAndCompany, getFullName } from "../speakers/Speaker"

type SessionSpeakerDetailsProps = {
    speaker: Contact
}
export const SessionSpeakerDetails: FC<SessionSpeakerDetailsProps> = ({speaker}) => {

    const speakerDetailsStyle = css`
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        padding-top: 5px;
    `

    const speakerNameStyle = css`
        text-align: left;
    `

    const speakerTitleStyle = css`
        text-align: left;
    `

    return <div css={speakerDetailsStyle}>
        <H4 css={speakerNameStyle}>{getFullName(speaker)} {speaker.category !== 'speaker' && <> ({speaker.categoryTitle})</>}</H4>
        <H5 css={speakerTitleStyle}>{getJobTitleAndCompany(speaker)}</H5>
    </div>

}