/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react";
import { PageSection } from "../common/PageSection";
import { Button } from "../common/Button";
import { LinkButton } from "../common/LinkButton";

export const supplementDomId = "supplement"

export const Supplement: FC = () => {

    const supplementStyle = css`
        display: block;
        width: 90%;
        max-width: 600px;
        margin: 40px auto 0;
    `

    return <PageSection sectionHeading="2024 Supplement" id={supplementDomId}>

        <div css={css`text-align: center;`}><Button href="https://times-ceosummit.com/resources/CEO_Summit_2024.pdf" css={css`margin: 10px auto`}>DOWNLOAD PDF</Button></div>

        {/* <div css={css`text-align: center; margin-top: 0px;`}><LinkButton css={css`margin-top: 30px`} to="../register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}

        <img css={supplementStyle} src="https://times-ceosummit.com/resources/ceo-supplement-2024.png" alt="Supplement" />

    </PageSection>

}