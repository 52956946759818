/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { RadioButtonsConfig } from "@marketpartner/backend-api"
import { FieldComponent } from "@marketpartner/mp-common-react"
import { ChangeEvent, FC } from "react"
import { FieldError } from '../FieldError'
type RadioButtonsProps = {
    choices: {
        value: string,
        label: string
    }[]
    value: string
    name: string
    label: string
    required: boolean
    setValue: (str: string) => void
    errors: string[]
}
const containerStyle = css`
    flex: 1 1 auto;
    display: flex;
    text-align: left;
    width: 300px;
    flex: 1 1 auto;
    font-size: 16px;
    flex-direction: column;
    margin-bottom: 8px;
`
const optionsStyle = css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`
const labelStyle = css`
    padding-bottom: 5px;
`

export const RadioButtons: FC<RadioButtonsProps> = ({
    choices,
    value,
    label,
    required,
    name,
    setValue,
    errors
}) => {
    return <div css={containerStyle}>
        <div css={labelStyle}>{ label }</div>
        <FieldError errors={errors} />
        <div css={optionsStyle}>
            { choices.map(choice => <Option 
                key={choice.value}
                value={choice.value}
                isOptionSelected={choice.value === value}
                isAnyOptionSelected={Boolean(value)}
                name={name}
                required={required}
                label={choice.label}
                setActive={(value: string) => setValue(value)}
                
            />) }
        </div>
    </div>
}

type OptionProps = {
    value: string
    isOptionSelected: boolean
    isAnyOptionSelected: boolean
    name: string
    required: boolean
    label: string
    setActive: (value: string) => void
}
const optionStyle = css`
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 10px;
    padding: 4px 10px;
    margin: 0 4px 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
`
const inputStyle = css`
    margin: 0;
    position: absolute;
    left: -1000px;
`
const Option:FC<OptionProps> = ({
    value,
    isOptionSelected,
    isAnyOptionSelected,
    name,
    required,
    label,
    setActive
}) => {
    return <label 
        css={css`
            ${optionStyle}
            ${isOptionSelected && css`border-color: #46a0a4; font-weight: 700;`}
            ${(isAnyOptionSelected && !isOptionSelected) && css`border-color: #bbb;`}
        `}
    >
        <input 
            type="radio" 
            name={name} 
            checked={isOptionSelected} 
            css={inputStyle} 
            required={required} 
            onChange={(e: ChangeEvent<HTMLInputElement>) => setActive(e.target.value)}
            value={value}
        />
        <div css={css`
            ${isOptionSelected && css`color: #46a0a4;`}
            ${(isAnyOptionSelected && !isOptionSelected) && css`color: #bbb;`}
        `}>{ label }</div> 
    </label>
}

export const DynamicRadioButtons: FieldComponent<RadioButtonsConfig> = ({
    value,
    setValue,
    config,
    errors
}) => {
    return <RadioButtons
        choices={config.choices}
        value={value ?? config.defaultValue}
        name={config.name}
        label={config.label}
        required={config.required}
        setValue={setValue}
        errors={errors}
    />
}