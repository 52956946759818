/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { EmailFieldConfig } from '@marketpartner/backend-api'
import { FieldComponent } from '@marketpartner/mp-common-react'
import { FC } from "react"
import { FieldError } from '../FieldError'
type EmailFieldProps = {
    value: string
    required: boolean
    placeholder: string
    setValue: (value: string) => void
    label: string
    errors: string[]
}
const containerStyle = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 300px;
    flex: 1 1 auto;
    font-size: 16px;
    margin-bottom: 10px;
`
const labelStyle = css`
    padding: 0 0 5px;
`
const inputStyle = css`
    height: 40px;
    border: 1px solid #000;
    outline: none;
    width: 100%;
    padding: 10px;
    font-size: 15px;
    color: #000;
`
export const EmailField: FC<EmailFieldProps> = ({
    value,
    required,
    placeholder,
    setValue,
    label,
    errors
}) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
    }
    return <label css={containerStyle}>
        <div css={labelStyle}>{ label }</div>
        <FieldError errors={errors} />
        <input 
            css={inputStyle}
            onChange={handleChange}
            required={required}
            value={value}
            type="email"
            placeholder={placeholder}
        />
    </label>
}

export const DynamicEmailField: FieldComponent<EmailFieldConfig> = ({
    value,
    setValue,
    config,
    errors
}) => <EmailField 
    value={value ?? config.defaultValue}
    setValue={setValue}
    required={config.required}
    placeholder={config.placeholder}
    label={config.label}
    errors={errors}
/>