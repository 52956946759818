/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { SponsorLogo } from "./SponsorLogo"
import { usePopupContext } from "../common/popup/PopupContext"
import { FC } from "react"
import { Company } from "../common/event-content-context/useEventContentFetch"

type SponsorProps = {
    sponsor: Company
}

export const Sponsor: FC<SponsorProps> = ({sponsor}) => {

    const sponsorStyle = css`
        width: 220px;
        height: 120px;
        cursor: pointer;
        border: 1px solid #e6e6e6;
        border-radius: 3px;
        display: flex;
        align-items: center;
    `
    
    const popupContext = usePopupContext()
    const showSponsorPopup = () => popupContext.showPopup({data: sponsor, type: 'sponsor'})

    return <div css={sponsorStyle} onClick={showSponsorPopup}>
            <SponsorLogo src={sponsor.logo_cropped} alt={sponsor.name} />
    </div>

}