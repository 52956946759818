/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FlatEpResource } from "@marketpartner/backend-api"
import { FC } from "react"

type SessionVideoProps = {
    resource: FlatEpResource
    sessionName: string
}

export const SessionVideo:FC<SessionVideoProps> = ({ resource, sessionName }) => {

    const videoStyle = css`
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        margin: 20px 0;
    `

    const vimeoStyle = css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `

    return <div key={resource.entity_token} css={videoStyle}>
        <iframe src={resource.url} frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen title={sessionName} css={vimeoStyle}></iframe>
    </div>
}