/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"
import { usePopupContext } from "./common/popup/PopupContext"
import { useScrollContext } from "./common/ScrollContext"

type AppContainerProps = {
    children: ReactNode
}

export const AppContainer: FC<AppContainerProps> = ({ children }) => {

    const { containerRef } = useScrollContext()

    const popupContext = usePopupContext()

    const style = css`
        overflow: ${popupContext.popupData ? 'hidden' : 'auto'};
        height: 100%;
    `
    return <div css={style} ref={containerRef}>{children}</div>
}