/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { PageSection } from "../common/PageSection"
import { LoadedAgenda } from "./LoadedAgenda"
import { Loading } from "../common/loading/Loading"
import { FC, useEffect } from "react"
import { useEventContentContext } from "../common/event-content-context/EventContentContext"
import { ExternalRegButton } from "../registration/ExternalRegButton"
import { P } from "../common/typography"

export const agendaDomId = "agenda"

export const Agenda: FC = () => {

    const { eventContentRequest } = useEventContentContext()

    useEffect(() => {
        eventContentRequest.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <PageSection sectionHeading="Agenda" id={agendaDomId} css={css`background-color: #f6f6f6;`}>
        {/* <P>Agenda to be announced</P> */}
        <Loading request={eventContentRequest} render={({agenda}) =>
            <LoadedAgenda 
                sessions={agenda.sessions} 
                dates={agenda.dates}
            />
        } />
        {/* <div css={css`text-align: center; margin-top: 30px;`}><LinkButton css={css`margin-top: 30px`} to="../register" scrollTo="register">REGISTER NOW</ LinkButton></div> */}
    </PageSection>

}