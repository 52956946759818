/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import { P } from "../common/typography"
import logo from "../images/the-times-ceo-summit.svg"
import { ReactComponent as Twitter } from './twitter.svg'
import { ReactComponent as Instagram } from './instagram.svg'
import { ReactComponent as Facebook } from './facebook.svg'
import { ReactComponent as Linkedin } from './linkedin.svg'
import { Link } from "../common/Link"

export const Footer: FC = () => {

    const copyrightStyle = css`
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        padding: 20px 0;
        background-color: #134682;
        color: #fff;
    `
    const logoStyle = css`
        max-width: 280px;
    `
    const linksStyle = css`
        display: flex;
        justify-content: center;
        align-items: center;
    `
    const linkStyle = css`
        color: #fff;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
            color: #00aae3;
        }
    `
    const svgStyle = css`
        fill: #fff;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        &:hover {
            fill: #00aae3;
        }
    `

    return <div css={copyrightStyle}>
        <img css={logoStyle} src={logo} alt="The Times" />
        <P>For all information and enquiries contact <Link href="mailto:ceosummit@thetimes.co.uk" css={linkStyle}>ceosummit@thetimes.co.uk</Link></P>
        {/* <P>For all information and enquiries contact <a href="mailto:rsvp@news.co.uk" css={linkStyle}>rsvp@news.co.uk</a></P> */}
        <div css={linksStyle}>
            <a href="https://www.linkedin.com/company/the-times/" target="_blank" rel="noreferrer">
                <Linkedin css={svgStyle} />
            </a>
            <a href="https://www.instagram.com/thetimes/?hl=en" target="_blank" rel="noreferrer">
                <Instagram css={svgStyle} />
            </a>
            <a href="https://twitter.com/thetimes?s=20" target="_blank" rel="noreferrer">
                <Twitter css={svgStyle} />
            </a>
            <a href="https://www.facebook.com/timesandsundaytimes/" target="_blank" rel="noreferrer">
                <Facebook css={svgStyle} />
            </a>
            <Link href="https://newsprivacy.co.uk/single/" target="_blank" css={linkStyle}>Privacy Policy</Link>
        </div>
    </div>

}