/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC, ReactNode } from "react"
import { scrollTo } from './utilityFunctions'

type ButtonProps = {
    children: ReactNode
    sectionId?: string
    href?: string
    className?: string
    onClick?: () => void
}

export const Button: FC<ButtonProps> = ({children, sectionId, href, ...props}) => {

    const buttonStyle = css`
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        border-radius: 3px;
        padding: 10px 20px 8px;
        color: #fff;
        background: #134682;
        text-decoration: none;
        box-shadow: 1px 1px 3px #5f5f5f;
        cursor: pointer;
    `

    return <>
    {
        href
            ? <a href={href} css={buttonStyle} target="_blank" rel="noreferrer" {...props}>{children}</a>
            : <div onClick={() => scrollTo(sectionId)} css={buttonStyle} {...props}>{children}</div>
    }
</>

}
