/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from "react"
import avatar from "../images/avatar.png"

type SpeakerAvatarProps = {
    src: string
    alt: string
}

export const SpeakerAvatar: FC<SpeakerAvatarProps> = ({src, alt}) => {

    const avatarStyle = css`
        border-radius: 50%;
        border: 1px solid #e6e6e6;
        width: 100px;
        box-shadow: 2px 3px 5px -1px #a5a5a5;
        margin-bottom: 10px;
    `

    return <img src={src ? src : avatar} alt={alt} css={avatarStyle} />

} 