/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FC } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { carouselImages } from './carouselImages';

export const Carousel: FC = () => {

    const carouselStyle = css`
        max-width: 600px;
        margin: 50px auto 0;
    `

    const imageStyle = css`
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 90%;
        margin: 0 auto;
        padding-top: 56.25%;
    `

    return <div css={carouselStyle}>
        <Slide>
            {carouselImages.map((carouselImage, index) => (
                <div className="each-slide" key={index}>
                    <div style={{ 'backgroundImage': `url(${carouselImage.url})` }} css={imageStyle}>
                        <span>{carouselImage.caption}</span>
                    </div>
                </div>
            ))}
        </Slide>
    </div>
}